import { reset } from 'redux-form';
import {
  addingEditingProfileSync, clearProfileSync, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import { RXFORM_PROFILE_SYNC, PAGE_MODE_TABLE } from '../../../constant';
import { addProfileSync, editProfileSync } from '../../../helper';
import downloadProfileSyncsAsync from './downloadProfileSyncsAsync';
import { isObject } from 'lodash';

export default (employeeId, fullName, email, phone, managerEmployeeId, jobTitle,
  department, division, company, branchId, dateOfBirth, gender) => async (dispatch, getState) => {
  try {
    dispatch(addingEditingProfileSync(true));

    const { token } = getState().authentication;
    const {
      tappedId, duplicateMode,
      selectedBranch, selectedManager,
    } = getState().uiProfileSync;

    const genderValue = () => {
      if (isObject(gender)) return gender?.value || null;
      if (gender) return gender;
      return null;
    };

    if (tappedId && !duplicateMode) {
      await editProfileSync(
        tappedId, fullName, email, phone,
        selectedManager || managerEmployeeId, jobTitle,
        department, division, company,
        selectedBranch || branchId, dateOfBirth, genderValue(), token,
      );
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addProfileSync(
        employeeId, fullName, email, phone,
        selectedManager || managerEmployeeId, jobTitle,
        department, division, company,
        selectedBranch || branchId, dateOfBirth, genderValue(), token,
      );
    }

    dispatch(reset(RXFORM_PROFILE_SYNC));
    dispatch(clearProfileSync());
    dispatch(downloadProfileSyncsAsync(1))
      .catch((error) => {
        dispatch(setAlertErrorMessage(error));
      });
  } finally {
    dispatch(addingEditingProfileSync(false));
  }
};
