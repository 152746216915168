import LocalizedStrings from 'react-localization';

const LocalizedString = new LocalizedStrings({
  'en-US': {
    analyticsMenu: {
      title: 'Analytics',
    },
    analyticsScreen: {
      title: 'Analytics',

      labelUsersByAppVersion: 'Users by App Version',
      labelUserEngagementsByScreenName: 'User Engagements by Screen Name',
      labelUsersByDeviceModel: 'Users by Device Model',
      labelCustomErrorMessages: 'Custom Event: application_error',
      labelActiveUsers: 'Active Users',
      labelActiveUsersinLast30Days: 'Active User in Last 30 Days',
      labelUserEngagementByScreenName: 'User engagement by Screen Name',
      labelUserByDeviceModel: 'User by device model',
      labelCustomParameter: 'Custom Parameter',
      labelEventCounts: 'Event Counts',
      labelItems: 'items',
      labelTitle: 'Title',
      labelPercentTotal: '% Total',
      labelAverageTime: 'Avg. time',
      labelPagination: '{pageRange} dari {totalPages}',
      labelStartDate: 'Start Date',
      labelEndDate: 'End Date',
      labelDeviceModel: 'Device Model',
      labelUsers: 'Users',
      labelComparisonStartDate: 'Comparison Start Date',
      labelComparisonEndDate: 'Comparison End Date',
      labelUserByCountry: 'Users by Country',
      labelCountry: 'Country',
    },
  },
  id: {
    analyticsMenu: {
      title: 'Analitik',
    },
    analyticsScreen: {
      title: 'Analitik',

      labelUsersByAppVersion: 'Pengguna Berdasarkan Versi Aplikasi ',
      labelUserEngagementsByScreenName: 'Keterlibatan Pengguna Berdasarkan Nama Layar',
      labelUsersByDeviceModel: 'Pengguna Berdasarkan Model Perangkat',
      labelCustomErrorMessages: 'Event Khusus: application_error',
      labelActiveUsers: 'Pengguna Aktif',
      labelActiveUsersinLast30Days: 'Pengguna Aktif di 30 Hari Terakhir',
      labelUserEngagementByScreenName: 'Keterlibatan pengguna berdasarkan Nama Layar',
      labelUserByDeviceModel: 'Pengguna berdasarkan model perangkat',
      labelCustomParameter: 'Parameter Khusus',
      labelEventCounts: 'Jumlah Event',
      labelItems: 'item',
      labelTitle: 'Judul',
      labelPercentTotal: 'Total %',
      labelAverageTime: 'Waktu rata-rata',
      labelPagination: '{pageRange} of {totalPages}',
      labelStartDate: 'Tanggal Mulai',
      labelEndDate: 'Tanggal Selesai',
      labelDeviceModel: 'Model Perangkat',
      labelUsers: 'Pengguna',
      labelComparisonStartDate: 'Tanggal Mulai Pembanding',
      labelComparisonEndDate: 'Tanggal Selesai Pembanding',
      labelUserByCountry: 'Pengguna berdasarkan Negara',
      labelCountry: 'Negara',
    },
  },
});

export default LocalizedString;
