import { connect } from 'react-redux';
import UserEngagementByScreenName from './user-engagement-by-screen-name.presentation';
import { sortDesc, getNDaysAgo, toMoment } from '../../../../helper/helper';
import {
  downloadAnalyticsUserEngagementsByScreenNameAsync, setAlertErrorMessage,
  setUserEngagementByScreenNameFilterDialogVisibility,
  setAnalyticsUserEngagementByScreenNameDateRanges,
} from '../../redux/action';
import {
  ANALYTICS_DATE_TWENTY_SEVEN_DAYS_AGO,
  ANALYTICS_DATE_YESTERDAY,
  ANALYTICS_DATE_FIFTY_FIVE_DAYS_AGO,
  ANALYTICS_DATE_TWENTY_EIGHT_DAYS_AGO,
  REVERSED_ISO_DATE_FORMAT,
} from '../../constant';

const convertToMinutes = (sec) => {
  let minutes = 0;
  if (sec >= 60) {
    minutes = Math.round(sec / 60);
  }
  const seconds = sec % 60;

  if (minutes === 0) return `${seconds}s`;
  return (`${minutes}m ${seconds < 10 ? `0${seconds}` : seconds}s`);
};

const getUserEngagementData = (state) => {
  const { userEngagementByScreenName } = state.analytics;

  const mappedData = userEngagementByScreenName?.dataArray?.length > 0
    ? userEngagementByScreenName?.dataArray?.map((data) => {
      const firstDateRangeEngagementPercent = Number((
        data.firstDateRange.engagementValue
        / userEngagementByScreenName.total.firstDateRangeTotal.engagementValue
      ) * 100);

      const secondDateRangeEngagementPercent = Number((
        data.secondDateRange.engagementValue
        / userEngagementByScreenName.total.secondDateRangeTotal.engagementValue
      ) * 100);

      return {
        ...data,
        firstDateRange: {
          ...data.firstDateRange,
          engagementPercentage: Number(firstDateRangeEngagementPercent.toFixed(1)),
          averageTime: convertToMinutes(Math.round(data.firstDateRange.averageTime)),
        },
        secondDateRange: {
          ...data.secondDateRange,
          engagementPercentage: Number(secondDateRangeEngagementPercent.toFixed(1)),
          averageTime: convertToMinutes(Math.round(data.secondDateRange.averageTime)),
        },
        percentChange: {
          engagement: {
            value: (Math.abs(
              (secondDateRangeEngagementPercent - firstDateRangeEngagementPercent)
                 / Math.abs(secondDateRangeEngagementPercent),
            ) * 100).toFixed(1),
            isDecreased: secondDateRangeEngagementPercent > firstDateRangeEngagementPercent,
          },
          averageTime: {
            value: (Math.abs(
              (data.secondDateRange.averageTime - data.firstDateRange.averageTime)
                 / Math.abs(data.secondDateRange.averageTime),
            ) * 100).toFixed(1),
            isDecreased: data.secondDateRange.averageTime > data.firstDateRange.averageTime,
          },
        },
      };
    }).sort((a, b) => sortDesc(
      a.firstDateRange.engagementPercentage, b.firstDateRange.engagementPercentage,
    )) : [];

  return mappedData || [];
};

const getTotalEngagementData = (state) => {
  const { userEngagementByScreenName } = state.analytics;
  return userEngagementByScreenName?.dataArray?.length || 0;
};

const getUserEngagementByScreenNameDateRange = (state, useFirstDateRange) => {
  const { userEngagementByScreenNameDateRange } = state.uiAnalytics;

  let dateFrom = useFirstDateRange
    ? userEngagementByScreenNameDateRange.firstDateRangeFrom
    : userEngagementByScreenNameDateRange.secondDateRangeFrom;
  let dateTo = useFirstDateRange
    ? userEngagementByScreenNameDateRange.firstDateRangeTo
    : userEngagementByScreenNameDateRange.secondDateRangeTo;

  if (useFirstDateRange) {
    if (
      userEngagementByScreenNameDateRange.firstDateRangeFrom
      === ANALYTICS_DATE_TWENTY_SEVEN_DAYS_AGO
    ) {
      dateFrom = getNDaysAgo(27);
    }

    if (
      userEngagementByScreenNameDateRange.firstDateRangeTo === ANALYTICS_DATE_YESTERDAY
    ) {
      dateTo = getNDaysAgo(1);
    }
  } else {
    if (
      userEngagementByScreenNameDateRange.secondDateRangeFrom === ANALYTICS_DATE_FIFTY_FIVE_DAYS_AGO
    ) {
      dateFrom = getNDaysAgo(55);
    }

    if (
      userEngagementByScreenNameDateRange.secondDateRangeTo === ANALYTICS_DATE_TWENTY_EIGHT_DAYS_AGO
    ) {
      dateTo = getNDaysAgo(28);
    }
  }

  return { from: dateFrom, to: dateTo };
};

const mapStateToProps = (state) => ({
  userEngagementByScreenName: getUserEngagementData(state),
  downloadingUserEngagementByScreenName: state.uiAnalytics.downloadingUserEngagementByScreenName,
  userEngagementByScreenNameFilterDialogVisibility:
    state.uiAnalytics.userEngagementByScreenNameFilterDialogVisibility,
  totalPages: getTotalEngagementData(state),
  userEngagementByScreenNameFirstDateRange: getUserEngagementByScreenNameDateRange(
    state, true,
  ),
  userEngagementByScreenNameSecondDateRange: getUserEngagementByScreenNameDateRange(
    state, false,
  ),
});

const mapDispatchToProps = (dispatch) => ({
  onFilterIconPressed: () => {
    dispatch(setUserEngagementByScreenNameFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setUserEngagementByScreenNameFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsUserEngagementByScreenNameDateRanges({
      firstDateRangeFrom: ANALYTICS_DATE_TWENTY_SEVEN_DAYS_AGO,
      firstDateRangeTo: ANALYTICS_DATE_YESTERDAY,
      secondDateRangeFrom: ANALYTICS_DATE_FIFTY_FIVE_DAYS_AGO,
      secondDateRangeTo: ANALYTICS_DATE_TWENTY_EIGHT_DAYS_AGO,
    }));
    dispatch(downloadAnalyticsUserEngagementsByScreenNameAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserEngagementByScreenNameFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (firstDateRange, secondDateRange) => {
    dispatch(setAnalyticsUserEngagementByScreenNameDateRanges({
      firstDateRangeFrom: toMoment(firstDateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      firstDateRangeTo: toMoment(firstDateRange.to).format(REVERSED_ISO_DATE_FORMAT),
      secondDateRangeFrom: toMoment(secondDateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      secondDateRangeTo: toMoment(secondDateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsUserEngagementsByScreenNameAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUserEngagementByScreenNameFilterDialogVisibility(false));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEngagementByScreenName);
