import downloadAnalyticsActiveUsersAsync from './downloadAnalyticsActiveUsersAsync';
import downloadAnalyticsApplicationErrorsAsync from './downloadAnalyticsApplicationErrorsAsync';
import downloadAnalyticsUserEngagementsByScreenNameAsync from './downloadAnalyticsUserEngagementsByScreenNameAsync';
import downloadAnalyticsUsersByAppVersionAsync from './downloadAnalyticsUsersByAppVersionAsync';
import downloadAnalyticsUsersByDeviceAsync from './downloadAnalyticsUsersByDeviceAsync';
import downloadAnalyticsUsersByCountryAsync from './downloadAnalyticsUsersByCountryAsync';

export default () => async (dispatch) => {
  await Promise.all([
    dispatch(downloadAnalyticsUsersByAppVersionAsync()),
    dispatch(downloadAnalyticsUsersByDeviceAsync()),
    dispatch(downloadAnalyticsApplicationErrorsAsync()),
    dispatch(downloadAnalyticsUserEngagementsByScreenNameAsync()),
    dispatch(downloadAnalyticsActiveUsersAsync()),
    dispatch(downloadAnalyticsUsersByCountryAsync()),
  ]);
};
