import {
  ANALYTICS_DATE_FIFTY_FIVE_DAYS_AGO, ANALYTICS_DATE_ONE_MONTH_AGO,
  ANALYTICS_DATE_TODAY, ANALYTICS_DATE_TWENTY_EIGHT_DAYS_AGO,
  ANALYTICS_DATE_TWENTY_SEVEN_DAYS_AGO, ANALYTICS_DATE_YESTERDAY,
} from '../../constant';
import {
  DOWNLOADING_ANALYTICS_COUNTRIES_BY_USERS,
  DOWNLOADING_ANALYTICS_CUSTOM_ERROR_MESSAGES,
  DOWNLOADING_ANALYTICS_DEVICES_BY_USERS, DOWNLOADING_ANALYTICS_EVENT_NAMES_COUNTS,
  DOWNLOADING_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME,
  DOWNLOADING_ANALYTICS_USERS_APP_VERSION_CHART_DATA,
  SET_ANALYTICS_CUSTOM_MESSAGES_DATE_RANGES,
  SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME_DATE_RANGES,
  SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA_DATE_RANGES,
  SET_CUSTOM_ERROR_MESSAGE_FILTER_DIALOG_VISIBILITY,
  SET_USER_ENGAGEMENT_BY_SCREEN_NAME_FILTER_DIALOG_VISIBILITY,
  SET_USERS_APP_VERSION_CHART_DATA_FILTER_DIALOG_VISIBILITY,
  SET_ACTIVE_USER_FILTER_DIALOG_VISIBILITY, SET_ANALYTICS_ACTIVE_USER_DATE_RANGES,
  SET_ANALYTICS_USER_BY_DEVICE_MODEL_DATE_RANGES,
  SET_USER_BY_DEVICE_MODEL_FILTER_DIALOG_VISIBILITY,
  DOWNLOADING_ANALYTICS_ACTIVE_USER_DATA,
  SET_ANALYTICS_USER_BY_COUNTRY_DATE_RANGES,
  SET_USER_BY_COUNTRY_FILTER_DIALOG_VISIBILITY,
} from '../action';

const initialState = {
  downloadingActiveUser: false,
  downloadingCountriesByUsers: false,
  downloadingDeviceModelsByUsers: false,
  downloadingEventNamesCounts: false,
  downloadingUsersAppVersionChartData: false,
  downloadingUserEngagementByScreenName: false,
  downloadingCustomErrorMessages: false,
  activeUserDateRange: {
    from: ANALYTICS_DATE_ONE_MONTH_AGO,
    to: ANALYTICS_DATE_TODAY,
  },
  customErrorMessageDateRange: {
    from: ANALYTICS_DATE_ONE_MONTH_AGO,
    to: ANALYTICS_DATE_TODAY,
  },
  userEngagementByScreenNameDateRange: {
    firstDateRangeFrom: ANALYTICS_DATE_TWENTY_SEVEN_DAYS_AGO,
    firstDateRangeTo: ANALYTICS_DATE_YESTERDAY,
    secondDateRangeFrom: ANALYTICS_DATE_FIFTY_FIVE_DAYS_AGO,
    secondDateRangeTo: ANALYTICS_DATE_TWENTY_EIGHT_DAYS_AGO,
  },
  usersAppVersionChartDataDateRange: {
    from: ANALYTICS_DATE_ONE_MONTH_AGO,
    to: ANALYTICS_DATE_TODAY,
  },
  userByDeviceModelDateRange: {
    from: ANALYTICS_DATE_ONE_MONTH_AGO,
    to: ANALYTICS_DATE_TODAY,
  },
  userByCountryDateRange: {
    from: ANALYTICS_DATE_ONE_MONTH_AGO,
    to: ANALYTICS_DATE_TODAY,
  },
  customErrorMessageFilterDialogVisibility: false,
  userEngagementByScreenNameFilterDialogVisibility: false,
  usersAppVersionChartDataFilterDialogVisibility: false,
  userByDeviceModelFilterDialogVisibility: false,
  activeUserFilterDialogVisibility: false,
  userByCountryFilterDialogVisibility: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ANALYTICS_ACTIVE_USER_DATA: {
      return { ...state, downloadingActiveUser: action.status };
    }
    case DOWNLOADING_ANALYTICS_COUNTRIES_BY_USERS: {
      return { ...state, downloadingCountriesByUsers: action.status };
    }
    case DOWNLOADING_ANALYTICS_DEVICES_BY_USERS: {
      return { ...state, downloadingDeviceModelsByUsers: action.status };
    }
    case DOWNLOADING_ANALYTICS_EVENT_NAMES_COUNTS: {
      return { ...state, downloadingEventNamesCounts: action.status };
    }
    case DOWNLOADING_ANALYTICS_USERS_APP_VERSION_CHART_DATA: {
      return { ...state, downloadingUsersAppVersionChartData: action.status };
    }
    case DOWNLOADING_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME: {
      return { ...state, downloadingUserEngagementByScreenName: action.status };
    }
    case DOWNLOADING_ANALYTICS_CUSTOM_ERROR_MESSAGES: {
      return { ...state, downloadingCustomErrorMessages: action.status };
    }
    case SET_ANALYTICS_ACTIVE_USER_DATE_RANGES: {
      return { ...state, activeUserDateRange: action.data };
    }
    case SET_ANALYTICS_CUSTOM_MESSAGES_DATE_RANGES: {
      return { ...state, customErrorMessageDateRange: action.data };
    }
    case SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME_DATE_RANGES: {
      return { ...state, userEngagementByScreenNameDateRange: action.data };
    }
    case SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA_DATE_RANGES: {
      return { ...state, usersAppVersionChartDataDateRange: action.data };
    }
    case SET_ANALYTICS_USER_BY_DEVICE_MODEL_DATE_RANGES: {
      return { ...state, userByDeviceModelDateRange: action.data };
    }
    case SET_ANALYTICS_USER_BY_COUNTRY_DATE_RANGES: {
      return { ...state, userByCountryDateRange: action.data };
    }
    case SET_USER_ENGAGEMENT_BY_SCREEN_NAME_FILTER_DIALOG_VISIBILITY: {
      return { ...state, userEngagementByScreenNameFilterDialogVisibility: action.status };
    }
    case SET_USERS_APP_VERSION_CHART_DATA_FILTER_DIALOG_VISIBILITY: {
      return { ...state, usersAppVersionChartDataFilterDialogVisibility: action.status };
    }
    case SET_CUSTOM_ERROR_MESSAGE_FILTER_DIALOG_VISIBILITY: {
      return { ...state, customErrorMessageFilterDialogVisibility: action.status };
    }
    case SET_USER_BY_DEVICE_MODEL_FILTER_DIALOG_VISIBILITY: {
      return { ...state, userByDeviceModelFilterDialogVisibility: action.status };
    }
    case SET_ACTIVE_USER_FILTER_DIALOG_VISIBILITY: {
      return { ...state, activeUserFilterDialogVisibility: action.status };
    }
    case SET_USER_BY_COUNTRY_FILTER_DIALOG_VISIBILITY: {
      return { ...state, userByCountryFilterDialogVisibility: action.status };
    }
    default:
      return state;
  }
};
