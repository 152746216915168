import { makeActionCreator } from '../../../../helper';

export * from '../../../../redux/action/simple-action';

export const DOWNLOADING_ANALYTICS_COUNTRIES_BY_USERS = 'DOWNLOADING_ANALYTICS_COUNTRIES_BY_USERS';
export const downloadingAnalyticsCountriesByUsers = makeActionCreator(DOWNLOADING_ANALYTICS_COUNTRIES_BY_USERS, 'status');

export const SET_ANALYTICS_COUNTRIES_BY_USERS = 'SET_ANALYTICS_COUNTRIES_BY_USERS';
export const setAnalyticsCountriesByUsers = makeActionCreator(SET_ANALYTICS_COUNTRIES_BY_USERS, 'data');

export const CLEAR_ANALYTICS_COUNTRIES_BY_USERS = 'CLEAR_ANALYTICS_COUNTRIES_BY_USERS';
export const clearAnalyticsCountriesByUsers = makeActionCreator(CLEAR_ANALYTICS_COUNTRIES_BY_USERS);

export const DOWNLOADING_ANALYTICS_DEVICES_BY_USERS = 'DOWNLOADING_ANALYTICS_DEVICES_BY_USERS';
export const downloadingAnalyticsDevicesByUsers = makeActionCreator(DOWNLOADING_ANALYTICS_DEVICES_BY_USERS, 'status');

export const SET_ANALYTICS_DEVICES_BY_USERS = 'SET_ANALYTICS_DEVICES_BY_USERS';
export const setAnalyticsDevicesByUsers = makeActionCreator(SET_ANALYTICS_DEVICES_BY_USERS, 'data');

export const CLEAR_ANALYTICS_DEVICES_BY_USERS = 'CLEAR_ANALYTICS_DEVICES_BY_USERS';
export const clearAnalyticsDevicesByUsers = makeActionCreator(CLEAR_ANALYTICS_DEVICES_BY_USERS);

export const DOWNLOADING_ANALYTICS_EVENT_NAMES_COUNTS = 'DOWNLOADING_ANALYTICS_EVENT_NAMES_COUNTS';
export const downloadingAnalyticsEventNamesCounts = makeActionCreator(DOWNLOADING_ANALYTICS_EVENT_NAMES_COUNTS, 'status');

export const SET_ANALYTICS_EVENT_NAMES_COUNTS = 'SET_ANALYTICS_EVENT_NAMES_COUNTS';
export const setAnalyticsEventNamesCounts = makeActionCreator(SET_ANALYTICS_EVENT_NAMES_COUNTS, 'data');

export const CLEAR_ANALYTICS_EVENT_NAMES_COUNTS = 'CLEAR_ANALYTICS_EVENT_NAMES_COUNTS';
export const clearAnalyticsEventNamesCounts = makeActionCreator(CLEAR_ANALYTICS_EVENT_NAMES_COUNTS);


export const DOWNLOADING_ANALYTICS_CUSTOM_ERROR_MESSAGES = 'DOWNLOADING_ANALYTICS_CUSTOM_ERROR_MESSAGES';
export const downloadingAnalyticsCustomErrorMessages = makeActionCreator(DOWNLOADING_ANALYTICS_CUSTOM_ERROR_MESSAGES, 'status');

export const SET_ANALYTICS_CUSTOM_ERROR_MESSAGES = 'SET_ANALYTICS_CUSTOM_ERROR_MESSAGES';
export const setAnalyticsCustomErrorMessages = makeActionCreator(SET_ANALYTICS_CUSTOM_ERROR_MESSAGES, 'data');

export const CLEAR_ANALYTICS_CUSTOM_ERROR_MESSAGES = 'CLEAR_ANALYTICS_CUSTOM_ERROR_MESSAGES';
export const clearAnalyticsCustomErrorMessages = makeActionCreator(
  CLEAR_ANALYTICS_CUSTOM_ERROR_MESSAGES,
);

export const DOWNLOADING_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME = 'DOWNLOADING_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME';
export const downloadingAnalyticsUserEngagementByScreenName = makeActionCreator(DOWNLOADING_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME, 'status');

export const SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME = 'SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME';
export const setAnalyticsUserEngagementByScreenName = makeActionCreator(SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME, 'data');

export const CLEAR_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME = 'CLEAR_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME';
export const clearAnalyticsUserEngagementByScreenName = makeActionCreator(
  CLEAR_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME,
);

export const DOWNLOADING_ANALYTICS_USERS_APP_VERSION_CHART_DATA = 'DOWNLOADING_ANALYTICS_USERS_APP_VERSION_CHART_DATA';
export const downloadingAnalyticsUsersAppVersionChartData = makeActionCreator(DOWNLOADING_ANALYTICS_USERS_APP_VERSION_CHART_DATA, 'status');

export const SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA = 'SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA';
export const setAnalyticsUsersAppVersionChartData = makeActionCreator(SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA, 'data');

export const CLEAR_ANALYTICS_USERS_APP_VERSION_CHART_DATA = 'CLEAR_ANALYTICS_USERS_APP_VERSION_CHART_DATA';
export const clearAnalyticsUsersAppVersionChartData = makeActionCreator(
  CLEAR_ANALYTICS_USERS_APP_VERSION_CHART_DATA,
);

export const SET_ANALYTICS_TOP_5_APP_VERSIONS = 'SET_ANALYTICS_TOP_5_APP_VERSIONS';
export const setAnalyticsTop5AppVersions = makeActionCreator(SET_ANALYTICS_TOP_5_APP_VERSIONS, 'data');

export const CLEAR_ANALYTICS_TOP_5_APP_VERSIONS = 'CLEAR_ANALYTICS_TOP_5_APP_VERSIONS';
export const clearAnalyticsTop5AppVersions = makeActionCreator(CLEAR_ANALYTICS_TOP_5_APP_VERSIONS);

export const SET_ANALYTICS_TOP_5_APPS_CHART_SERIES = 'SET_ANALYTICS_TOP_5_APPS_CHART_SERIES';
export const setAnalyticsTop5AppsChartSeries = makeActionCreator(SET_ANALYTICS_TOP_5_APPS_CHART_SERIES, 'data');

export const CLEAR_ANALYTICS_TOP_5_APPS_CHART_SERIES = 'CLEAR_ANALYTICS_TOP_5_APPS_CHART_SERIES';
export const clearAnalyticsTop5AppsChartSeries = makeActionCreator(
  CLEAR_ANALYTICS_TOP_5_APPS_CHART_SERIES,
);


export const DOWNLOADING_ANALYTICS_ACTIVE_USER_DATA = 'DOWNLOADING_ANALYTICS_ACTIVE_USER_DATA';
export const downloadingAnalyticsActiveUserChartData = makeActionCreator(DOWNLOADING_ANALYTICS_ACTIVE_USER_DATA, 'status');

export const SET_ANALYTICS_ACTIVE_USER_CHART_DATA = 'SET_ANALYTICS_ACTIVE_USER_CHART_DATA';
export const setAnalyticsActiveUserChartData = makeActionCreator(SET_ANALYTICS_ACTIVE_USER_CHART_DATA, 'data');

export const CLEAR_ANALYTICS_ACTIVE_USER_CHART_DATA = 'CLEAR_ANALYTICS_ACTIVE_USER_CHART_DATA';
export const clearAnalyticsActiveUserChartData = makeActionCreator(
  CLEAR_ANALYTICS_ACTIVE_USER_CHART_DATA,
);

export const SET_ANALYTICS_TOTAL_ACTIVE_USER = 'SET_ANALYTICS_TOTAL_ACTIVE_USER';
export const setAnalyticsTotalActiveUser = makeActionCreator(SET_ANALYTICS_TOTAL_ACTIVE_USER, 'total');


export const SET_ANALYTICS_ACTIVE_USER_DATE_RANGES = 'SET_ANALYTICS_ACTIVE_USER_DATE_RANGES';
export const setAnalyticsActiveUserDateRanges = makeActionCreator(SET_ANALYTICS_ACTIVE_USER_DATE_RANGES, 'data');

export const SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME_DATE_RANGES = 'SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME_DATE_RANGES';
export const setAnalyticsUserEngagementByScreenNameDateRanges = makeActionCreator(SET_ANALYTICS_USER_ENGAGEMENT_BY_SCREEN_NAME_DATE_RANGES, 'data');

export const SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA_DATE_RANGES = 'SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA_DATE_RANGES';
export const setAnalyticsUsersAppVersionChartDataDateRanges = makeActionCreator(SET_ANALYTICS_USERS_APP_VERSION_CHART_DATA_DATE_RANGES, 'data');

export const SET_ANALYTICS_CUSTOM_MESSAGES_DATE_RANGES = 'SET_ANALYTICS_CUSTOM_MESSAGES_DATE_RANGES';
export const setAnalyticsCustomMessagesDateRanges = makeActionCreator(SET_ANALYTICS_CUSTOM_MESSAGES_DATE_RANGES, 'data');

export const SET_ANALYTICS_USER_BY_DEVICE_MODEL_DATE_RANGES = 'SET_ANALYTICS_USER_BY_DEVICE_MODEL_DATE_RANGES';
export const setAnalyticsUserByDeviceModelDateRanges = makeActionCreator(SET_ANALYTICS_USER_BY_DEVICE_MODEL_DATE_RANGES, 'data');

export const SET_ANALYTICS_USER_BY_COUNTRY_DATE_RANGES = 'SET_ANALYTICS_USER_BY_COUNTRY_DATE_RANGES';
export const setAnalyticsUserByCountryDateRanges = makeActionCreator(SET_ANALYTICS_USER_BY_COUNTRY_DATE_RANGES, 'data');

export const SET_USER_ENGAGEMENT_BY_SCREEN_NAME_FILTER_DIALOG_VISIBILITY = 'SET_USER_ENGAGEMENT_BY_SCREEN_NAME_FILTER_DIALOG_VISIBILITY';
export const setUserEngagementByScreenNameFilterDialogVisibility = makeActionCreator(SET_USER_ENGAGEMENT_BY_SCREEN_NAME_FILTER_DIALOG_VISIBILITY, 'status');

export const SET_USERS_APP_VERSION_CHART_DATA_FILTER_DIALOG_VISIBILITY = 'SET_USERS_APP_VERSION_CHART_DATA_FILTER_DIALOG_VISIBILITY';
export const setUsersAppVersionChartDataFilterDialogVisibility = makeActionCreator(SET_USERS_APP_VERSION_CHART_DATA_FILTER_DIALOG_VISIBILITY, 'status');

export const SET_CUSTOM_ERROR_MESSAGE_FILTER_DIALOG_VISIBILITY = 'SET_CUSTOM_ERROR_MESSAGE_FILTER_DIALOG_VISIBILITY';
export const setCustomErrorMessageFilterDialogVisibility = makeActionCreator(SET_CUSTOM_ERROR_MESSAGE_FILTER_DIALOG_VISIBILITY, 'status');

export const SET_USER_BY_DEVICE_MODEL_FILTER_DIALOG_VISIBILITY = 'SET_USER_BY_DEVICE_MODEL_FILTER_DIALOG_VISIBILITY';
export const setUserByDeviceModelFilterDialogVisibility = makeActionCreator(SET_USER_BY_DEVICE_MODEL_FILTER_DIALOG_VISIBILITY, 'status');

export const SET_ACTIVE_USER_FILTER_DIALOG_VISIBILITY = 'SET_ACTIVE_USER_FILTER_DIALOG_VISIBILITY';
export const setActiveUserFilterDialogVisibility = makeActionCreator(SET_ACTIVE_USER_FILTER_DIALOG_VISIBILITY, 'status');

export const SET_USER_BY_COUNTRY_FILTER_DIALOG_VISIBILITY = 'SET_USER_BY_COUNTRY_FILTER_DIALOG_VISIBILITY';
export const setUserByCountryFilterDialogVisibility = makeActionCreator(SET_USER_BY_COUNTRY_FILTER_DIALOG_VISIBILITY, 'status');
