import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress, Paper, makeStyles, IconButton,
} from '@material-ui/core';
import {
  Chart, Legend, Title, ValueAxis, Tooltip,
} from '@devexpress/dx-react-chart-material-ui';
import {
  Animation, EventTracker, HoverState, ArgumentAxis,
} from '@devexpress/dx-react-chart';
import { Tune } from '@material-ui/icons';
import { COLOR_PRIMARY } from '../constant';
import { H3 } from './labels';
import { toCurrency } from '../helper';

const useStyles = makeStyles(() => ({
  loading: {
    display: 'flex',
    height: 550,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    border: '.5px solid #dadce0',
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
  },
  container: {
    border: '.5px solid #dadce0',
    boxShadow: '0px 4px 14px 0px rgba(0,0,0,0.03)',
  },
  title: {
    fontSize: '24px',
    position: 'relative',
    left: '45px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 10px 20px 0px',
  },
}));

const LegendRoot = (props) => (
  <Legend.Root {...props} style={{ display: 'flex', flexDirection: 'row' }} />
);
const LegendLabel = (props) => (
  <Legend.Label style={{ pt: 1, whiteSpace: 'nowrap' }} {...props} />
);

const renderTitle = (title, useFilterButton, onFilterIconPressed, classes) => (useFilterButton
  ? (
    <div className={classes.titleContainer}>
      <div />
      <H3 className={classes.title}>
        {title}
      </H3>
      <IconButton edge="end" onClick={onFilterIconPressed}>
        <Tune style={{ fill: COLOR_PRIMARY }} />
      </IconButton>
    </div>
  )
  : (
    <Title style={{ fontSize: 10 }} text={title} />
  ));

const TooltipContent = (data, chartSeries, {
  text, style, targetItem, ...props
}) => {
  const alignStyle = {
    ...style,
    paddingLeft: '10px',
  };
  const items = chartSeries.map(({ name, key, color }) => {
    const val = data[targetItem.point];
    return (
      <tbody key={key}>
        <tr>
          <td>
            <svg width="10" height="10">
              <circle cx="5" cy="5" r="5" fill={color} />
            </svg>
          </td>
          <td>
            <Tooltip.Content style={alignStyle} text={name} {...props} />
          </td>
          <td align="right">
            <Tooltip.Content style={alignStyle} text={toCurrency(val[key])} {...props} />
          </td>
        </tr>
      </tbody>
    );
  });
  return (
    <table>
      {items}
    </table>
  );
};

const AnalyticsLineChart = ({
  data, loading, title, children, chartSeries,
  useFilterButton, onFilterIconPressed,
}) => {
  const classes = useStyles();
  const [target, setTarget] = useState(null);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
      </div>
    );
  }
  return (
    <Paper className={classes.container}>
      <Chart data={data}>
        <ArgumentAxis showLine={false} />
        <ValueAxis />
        {children}
        <Legend position="bottom" rootComponent={LegendRoot} labelComponent={LegendLabel} />
        {renderTitle(title, useFilterButton, onFilterIconPressed, classes)}
        <Animation />
        <EventTracker />
        <HoverState />
        <Tooltip
          targetItem={target}
          onTargetItemChange={(t) => setTarget(t)}
          contentComponent={({
            text, style, targetItem, props,
          }) => TooltipContent(data, chartSeries, {
            text, style, targetItem, props,
          })}
        />
        <Animation />
      </Chart>
    </Paper>
  );
};

export default AnalyticsLineChart;

AnalyticsLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartSeries: PropTypes.arrayOf(PropTypes.object).isRequired,

  loading: PropTypes.bool,
  useFilterButton: PropTypes.bool,

  title: PropTypes.string.isRequired,

  children: PropTypes.node.isRequired,

  onFilterIconPressed: PropTypes.func,
};

AnalyticsLineChart.defaultProps = {
  loading: false,
  useFilterButton: false,
  onFilterIconPressed: () => {},
};
