import {
  downloadingDeletingProfileSync, addProfileSync,
  setProfileSyncSelectedBranchId, setProfileSyncSelectedManagerId,
} from '../simple-action';
import { downloadProfileSync } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingProfileSync(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiProfileSync;
    const { tappedId: setAchievementTappedId } = getState().uiAchievement;

    const result = await downloadProfileSync(setAchievementTappedId || tappedId, token);

    dispatch(addProfileSync(result));
    if (result?.branchId){
      dispatch(setProfileSyncSelectedBranchId(result.branchId));
    }
    if (result?.managerEmployeeId){
      dispatch(setProfileSyncSelectedManagerId(result.managerEmployeeId));
    }
  } finally {
    dispatch(downloadingDeletingProfileSync(false));
  }
};
